@import url('https://fonts.googleapis.com/css?family=Raleway&display=swap');

hr {
    border: 1px solid #ddd;
    margin-left: 20px;
    margin-right: 20px;
    color: #ddd;
}

h1 {
    font-family: 'Raleway', sans-serif;
    margin: 20px;
    margin-bottom: 30px;
}

h2 {
    font-family: 'Raleway', sans-serif;
    margin: 20px;
    margin-bottom: 0em;
}

h3 {
    font-family: 'Raleway', sans-serif;
    color: #00aadd;
    margin: 20px;
    margin-bottom: 0px;
}

h4 {
    font-family: 'Raleway', sans-serif;
    color: #000;
    margin: 20px;
    margin-bottom: -5px;
}
p {
    margin: 20px;
}

a {
    color: #00aadd;
    text-decoration: none;
}

li {
    margin-left: 10px;
}

table {
    margin: 20px;
}

th {
    font-weight: normal;
    vertical-align: top;
    padding-right: 20px;
}

blockquote {
    border-left-style: solid;
    border-left-color: #15ccfe;
    margin-left: 20px;
}
blockquote span {
    color: #15ccfe;
}

.Markdown-block-flex {
    background-color: #fff;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}
.Markdown-block {
    padding: 20px;
    margin-bottom: 50px;
}
.spaced {
    margin-top: 50px;
}
.dense {
    margin-bottom: 30px;
}
.packed {
    margin-bottom: 0px;
}

.Markdown-greyspace {
    background-color: #ddd;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Markdown-spacing {
    width: 50px;
}
.Markdown-details {
    margin-left: 20px;
    margin-right: 20px;
    padding: 1px;
}
.Markdown-block summary {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: 900;
    font-size: 1.2em;
    color: #00aadd;
    background-color: #fff;
}
.Markdown-image {
    flex: 1;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
}
.Markdown-image-header {
    flex: 1;
    width: 100%;
    max-height: 120px;
    overflow: hidden;
    object-fit: cover;
}
.contain {
    object-fit: contain;
}
.Markdown-event {
    flex: 2;
    padding: 0px;
    text-align: left;
}
.Markdown-event > h3 {
    margin-bottom: -15px;
}
.Markdown-profile-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.Markdown-profile {
    margin: 20px;
    min-width: 190px;
    max-width: 190px;
    overflow: hidden;
    object-fit: cover;
    text-align: center;
}
.Markdown-image-profile {
    width: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 500px) {
    .Markdown-block {
        padding-left: 0px;
        padding-left: 0px;
    }
    .Markdown-block-flex {
        padding-left: 0px;
        padding-right: 0px;
        flex-direction: column;
    }
}

.Markdown-event-summary {
    list-style: none;
    cursor: pointer;
    padding: 0px;
    /* I have no idea why I have to do this*/
    /*outline: none;*/
}
.Markdown-event-details {
    margin-left: 20px;
    margin-right: 20px;
    padding: 1px;
    margin: 0px;
    background-color: #ccc;
}
