.Footer-wrapper {
    margin-top: 50px;
}
.Footer-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #222;
    color: #fff;
    font-size: 1.1em;
    text-align: center;
}
.Footer-tkrt {
    background-image: url("/images/footer.png");
    background-repeat: repeat-x;
    height: 50px;
  }