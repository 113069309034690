.App {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: top;
  -webkit-text-size-adjust: none;
  background-color: #ddd;
}

.App-header {
  height: 20em;
  align-items: center;
  justify-content: center;
}

#App-header-image {
  width: 100%;
  height: 24em;
  object-fit: cover;
}

.App-link {
  color: #15ccfe;
}

.App-center {
  width: 100%;
  /*min-width: 350px;*/
  max-width: 960px;
  text-align: left;
  align-content: center;
  background-color: #ddd;
  margin-left: 20px;
  margin-right: 20px;
}

/*.App-edge {
  width: 0;
  height: 100%;
  position: relative;
  flex-grow: 1;
  margin: 20px;
}*/

.App-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue {
  background-color: #15ccfe;
}
.dark {
  background-color: #222; 
}
.grey {
  background-color: #ddd;
}
.white {
  background-color: #fff;
}

.App-content {
  min-height: 600px;
}

@media only screen and (max-width: 500px) {
  /*.App-edge {
    margin: 0px;
  }*/

  .App-center {
    margin-left: 0px;
    margin-right: 0px;
  }
}