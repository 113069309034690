.Topbar-container {
    width: 100%;
    background-color: #15ccfe;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: center;
}
#Topbar-highlight {
    /* background-image: linear-gradient(#85e4ff, #15ccfe);*/
    color: white;
    border-bottom: 5px white solid;
    font-weight: 900;
}
.Topbar-logo {
    z-index: 1;
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    height: 0px;
}
.Topbar-links {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}
.Topbar-link {
    min-width: 50px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: black;
}
.Topbar-language {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 720px) {
    .Topbar-container {
        flex-direction: column;
    }
    .Topbar-logo {
        height: auto;
    }
  }