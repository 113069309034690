@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Raleway&display=swap);
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  background-color: #15ccfe;
}
.App {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: top;
  -webkit-text-size-adjust: none;
  background-color: #ddd;
}

.App-header {
  height: 20em;
  align-items: center;
  justify-content: center;
}

#App-header-image {
  width: 100%;
  height: 24em;
  object-fit: cover;
}

.App-link {
  color: #15ccfe;
}

.App-center {
  width: 100%;
  /*min-width: 350px;*/
  max-width: 960px;
  text-align: left;
  align-content: center;
  background-color: #ddd;
  margin-left: 20px;
  margin-right: 20px;
}

/*.App-edge {
  width: 0;
  height: 100%;
  position: relative;
  flex-grow: 1;
  margin: 20px;
}*/

.App-section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.blue {
  background-color: #15ccfe;
}
.dark {
  background-color: #222; 
}
.grey {
  background-color: #ddd;
}
.white {
  background-color: #fff;
}

.App-content {
  min-height: 600px;
}

@media only screen and (max-width: 500px) {
  /*.App-edge {
    margin: 0px;
  }*/

  .App-center {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.Topbar-container {
    width: 100%;
    background-color: #15ccfe;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: center;
}
#Topbar-highlight {
    /* background-image: linear-gradient(#85e4ff, #15ccfe);*/
    color: white;
    border-bottom: 5px white solid;
    font-weight: 900;
}
.Topbar-logo {
    z-index: 1;
    margin-top: 1em;
    margin-left: 2em;
    margin-right: 2em;
    height: 0px;
}
.Topbar-links {
    flex: 1 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}
.Topbar-link {
    min-width: 50px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: black;
}
.Topbar-language {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media only screen and (max-width: 720px) {
    .Topbar-container {
        flex-direction: column;
    }
    .Topbar-logo {
        height: auto;
    }
  }
hr {
    border: 1px solid #ddd;
    margin-left: 20px;
    margin-right: 20px;
    color: #ddd;
}

h1 {
    font-family: 'Raleway', sans-serif;
    margin: 20px;
    margin-bottom: 30px;
}

h2 {
    font-family: 'Raleway', sans-serif;
    margin: 20px;
    margin-bottom: 0em;
}

h3 {
    font-family: 'Raleway', sans-serif;
    color: #00aadd;
    margin: 20px;
    margin-bottom: 0px;
}

h4 {
    font-family: 'Raleway', sans-serif;
    color: #000;
    margin: 20px;
    margin-bottom: -5px;
}
p {
    margin: 20px;
}

a {
    color: #00aadd;
    text-decoration: none;
}

li {
    margin-left: 10px;
}

table {
    margin: 20px;
}

th {
    font-weight: normal;
    vertical-align: top;
    padding-right: 20px;
}

blockquote {
    border-left-style: solid;
    border-left-color: #15ccfe;
    margin-left: 20px;
}
blockquote span {
    color: #15ccfe;
}

.Markdown-block-flex {
    background-color: #fff;
    margin-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}
.Markdown-block {
    padding: 20px;
    margin-bottom: 50px;
}
.spaced {
    margin-top: 50px;
}
.dense {
    margin-bottom: 30px;
}
.packed {
    margin-bottom: 0px;
}

.Markdown-greyspace {
    background-color: #ddd;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Markdown-spacing {
    width: 50px;
}
.Markdown-details {
    margin-left: 20px;
    margin-right: 20px;
    padding: 1px;
}
.Markdown-block summary {
    cursor: pointer;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 5px;
    padding-top: 5px;
    font-weight: 900;
    font-size: 1.2em;
    color: #00aadd;
    background-color: #fff;
}
.Markdown-image {
    flex: 1 1;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
}
.Markdown-image-header {
    flex: 1 1;
    width: 100%;
    max-height: 120px;
    overflow: hidden;
    object-fit: cover;
}
.contain {
    object-fit: contain;
}
.Markdown-event {
    flex: 2 1;
    padding: 0px;
    text-align: left;
}
.Markdown-event > h3 {
    margin-bottom: -15px;
}
.Markdown-profile-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
.Markdown-profile {
    margin: 20px;
    min-width: 190px;
    max-width: 190px;
    overflow: hidden;
    object-fit: cover;
    text-align: center;
}
.Markdown-image-profile {
    width: 100%;
    object-fit: cover;
}

@media only screen and (max-width: 500px) {
    .Markdown-block {
        padding-left: 0px;
        padding-left: 0px;
    }
    .Markdown-block-flex {
        padding-left: 0px;
        padding-right: 0px;
        flex-direction: column;
    }
}

.Markdown-event-summary {
    list-style: none;
    cursor: pointer;
    padding: 0px;
    /* I have no idea why I have to do this*/
    /*outline: none;*/
}
.Markdown-event-details {
    margin-left: 20px;
    margin-right: 20px;
    padding: 1px;
    margin: 0px;
    background-color: #ccc;
}

.Footer-wrapper {
    margin-top: 50px;
}
.Footer-container {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #222;
    color: #fff;
    font-size: 1.1em;
    text-align: center;
}
.Footer-tkrt {
    background-image: url("/images/footer.png");
    background-repeat: repeat-x;
    height: 50px;
  }
